import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Container, MemberDataContainer, TabContainer, TabDescription, TabsContainer } from './styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TaskSelectedContext } from 'Tasking_Contexts/TaskSelectedContext';
import { ActivitySelectedContext } from 'Tasking_Contexts/ActivitySelectedContext';
import { AssignmentTurnedIn, History, LocalFlorist, Person, MedicalInformation } from '@mui/icons-material';
import { calculateAge } from 'Util/dateFunctions';
import {
  ENROLLED,
  ENROLLED_HISTORY,
  ENROLLED_REDETERMINATION,
  ENROLLED_TASKS,
  ENROLLED_TASKS_EDIT,
  ENROLLED_TASKS_EDIT_ACTIVITY,
  ENROLLED_TASKS_EDIT_ACTIVITY_VIEW,
  ENROLLED_THRIVE,
  ENROLLED_MEMBERCONNECTION_NEW,
  NONENROLLED,
  NONENROLLED_HISTORY,
  NONENROLLED_REDETERMINATION,
  NONENROLLED_TASKS,
  NONENROLLED_TASKS_EDIT,
  NONENROLLED_TASKS_EDIT_ACTIVITY,
  NONENROLLED_TASKS_EDIT_ACTIVITY_VIEW,
  NONENROLLED_MEMBERCONNECTION_NEW,
} from '../../../initialization/Routes/RoutesConfig';
import urlcat from 'urlcat';

const tabsConfig = [
  {
    type: 'tasks',
    description: 'TASKS',
    icon: AssignmentTurnedIn,
  },
  {
    type: 'user',
    description: 'MEMBER INFO',
    icon: Person,
  },
  {
    type: 'connection',
    description: 'MEMBER CONNECTION',
    icon: Person,
  },
  {
    type: 'redetermination',
    description: 'REDETERMINATION',
    icon: MedicalInformation,
  },
  {
    type: 'history',
    description: 'HISTORY',
    icon: History,
  },
  {
    type: 'thrive',
    description: 'THRIVE',
    icon: LocalFlorist,
  },
];

const MemberInfoTabs = ({ memberInfo, ngm, currentTab }) => {
  const isMemberPendingRegistration = memberInfo?.userId && !memberInfo.activeProfileId;
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, ngmId, orgId } = useParams();
  const { selectedTask } = useContext(TaskSelectedContext);
  const { selectedActivity } = useContext(ActivitySelectedContext);
  const isUnderAge = calculateAge(memberInfo?.birthDateUtc) < 13;
  const isHistoryTab = location?.pathname.includes('history');

  const tabClicked = (data) => {
    if (data === 'history') {
      navigate(
        ngm
          ? urlcat(NONENROLLED_HISTORY, { orgId: orgId, ngmId: ngmId })
          : urlcat(ENROLLED_HISTORY, { userId: userId }),
      );
    }

    if (data === 'user') {
      navigate(ngm ? urlcat(NONENROLLED, { orgId: orgId, ngmId: ngmId }) : urlcat(ENROLLED, { userId: userId }));
    }

    if (data === 'redetermination') {
      navigate(
        ngm
          ? urlcat(NONENROLLED_REDETERMINATION, { orgId: orgId, ngmId: ngmId })
          : urlcat(ENROLLED_REDETERMINATION, { userId: userId }),
      );
    }

    if (data === 'thrive') {
      navigate(urlcat(ENROLLED_THRIVE, { userId: userId }));
    }

    if (data === 'connection') {
      navigate(
        ngm
          ? urlcat(NONENROLLED_MEMBERCONNECTION_NEW, { orgId: orgId, ngmId: ngmId })
          : urlcat(ENROLLED_MEMBERCONNECTION_NEW, { userId: userId }),
      );
    }

    if (data === 'tasks') {
      if (selectedTask?.taskGuid && selectedActivity?.guid) {
        navigate(
          ngm
            ? urlcat(NONENROLLED_TASKS_EDIT_ACTIVITY_VIEW, {
                orgId: orgId,
                ngmId: ngmId,
                taskGuid: selectedTask?.taskGuid,
                activityGuid: selectedActivity?.guid,
              })
            : urlcat(ENROLLED_TASKS_EDIT_ACTIVITY_VIEW, {
                userId: userId,
                taskGuid: selectedTask?.taskGuid,
                activityGuid: selectedActivity?.guid,
              }),
          { state: { showActivity: true } },
        );
      } else if (selectedActivity || location?.state?.showActivity) {
        navigate(
          ngm
            ? urlcat(NONENROLLED_TASKS_EDIT_ACTIVITY, { orgId: orgId, ngmId: ngmId, taskGuid: selectedTask?.taskGuid })
            : urlcat(ENROLLED_TASKS_EDIT_ACTIVITY, { userId: userId, taskGuid: selectedTask?.taskGuid }),
          { state: { showActivity: true } },
        );
      } else if (selectedTask?.taskGuid) {
        navigate(
          ngm
            ? urlcat(NONENROLLED_TASKS_EDIT, { orgId: orgId, ngmId: ngmId, taskGuid: selectedTask?.taskGuid })
            : urlcat(ENROLLED_TASKS_EDIT, { userId: userId, taskGuid: selectedTask?.taskGuid }),
        );
      } else {
        navigate(
          ngm ? urlcat(NONENROLLED_TASKS, { orgId: orgId, ngmId: ngmId }) : urlcat(ENROLLED_TASKS, { userId: userId }),
        );
      }
    }
  };

  const tabsToDisplay = () => {
    if (isMemberPendingRegistration) {
      return tabsConfig.filter((tab) => tab.type === 'user');
    }
    if (ngm) {
      return tabsConfig.filter((tab) => tab.type !== 'thrive');
    }

    return tabsConfig;
  };

  return (
    <MemberDataContainer
      ngm={ngm}
      deceased={memberInfo?.deceased}
      deleted={memberInfo?.deleted}
      declined={memberInfo?.declined}
      underage={isUnderAge}
      doNotCall={memberInfo?.doNotCall}
      data-testid="MemberTabIcons"
    >
      <TabsContainer ngm={ngm}>
        {tabsToDisplay()?.map((data, index) => (
          <Container
            ngm={ngm}
            memberInfo={memberInfo}
            data-testid={`IconContainer${index}`}
            key={index}
            onClick={() => tabClicked(data.type)}
          >
            <TabContainer
              ngm={ngm}
              isHistoryTab={isHistoryTab && data.type === currentTab}
              clicked={!isHistoryTab && data.type === currentTab}
              declined={memberInfo?.declined}
              deceased={memberInfo?.deceased}
              deleted={memberInfo?.deleted}
              underage={isUnderAge}
              doNotCall={memberInfo?.doNotCall}
            >
              <data.icon />
            </TabContainer>
            <TabDescription>{data.description}</TabDescription>
          </Container>
        ))}
      </TabsContainer>
    </MemberDataContainer>
  );
};

MemberInfoTabs.propTypes = {
  currentTab: PropTypes.string,
  memberInfo: PropTypes.object,
  ngm: PropTypes.bool,
};

export default MemberInfoTabs;
