import styled from 'styled-components';

export const CustomersContainer = styled.div`
  box-shadow: 1px 2px 11px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

export const Customers = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

export const CustomerData = styled.td`
  white-space: nowrap;
  border-bottom: 0.75px solid #e4e6e3;
  color: #0f145b;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  font-family: Open sans;
  max-width: 200px;
  overflow: hidden;
  cursor: ${(props) => (props.assignedTo ? 'pointer' : '')};

  &:first-child {
    padding-left: 15px;
  }

  &:hover:not(:first-child) {
    overflow: visible;
    position: relative;
    text-wrap: wrap;
    margin-top: 2.65px;
    z-index: 1;
  }
`;

export const CustomerDataHeader = styled.th`
  overflow: hidden;
  font-family: Karla;
  letter-spacing: 0.25px;
  text-align: left;
  color: #015353;
  background-color: #f2f2f2;
  padding-top: 16px;
  padding-bottom: 16px;
  white-space: nowrap;

  width: auto;

  border: none;

  &[colspan='2'] {
    width: 12%;
  }

  &:first-child {
    padding-left: 15px;
  }
`;

export const PaginationContent = styled.div`
  font-family: Karla;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  text-align: right;
  padding-right: 10px;
  background-color: #f2f2f2;
  height: 40px;
  color: #015353;
`;

export const CustomerTableSections = styled.tr`
  &:hover {
    background-color: #d9f7f7;
  }
`;

export const StatusBox = styled.div`
  display: inline-flex;
  background-color: ${(props) =>
    props.complete
      ? '#00a2ff'
      : props.overdue
      ? '#DE2B1B'
      : props.overdueOneDay
      ? '#EA1DCB'
      : props.current
      ? '#62A942'
      : ''};
  height: 22px;
  width: 86px;
  color: white;
  border-radius: 5px;
  font-family: Karla;
  letter-spacing: 0.25px;
  font-weight: bold;
  font-size: 10px;
  align-items: center;
  justify-content: center;
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #015353;
  min-height: 65px;
  padding: 0px 20px;
  justify-content: space-between;
  gap: 10px;
`;

export const SelectStyle = styled.select`
  width: 190px;
  height: 32px;
  margin-left: 20px;
  border-radius: 9px;
  color: #0f145b;
`;

export const NoDataMessage = styled.div`
  margin-top: 38px;
  font-family: Karla;
  font-size: 18px;
  color: red;
  margin-bottom: -20px;
`;

export const NoDataMessageTabs = styled.div`
  margin-top: 38px;
  font-family: Karla;
  font-size: 18px;
  color: red;
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Tabs = styled.div`
  display: flex;
  margin-top: 50px;
`;

export const Tab = styled.button`
  box-shadow: ${(props) => (props.selected ? 'none' : '1px 2px 11px 0px rgba(0, 0, 0, 0.25)')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? '#FFFFFF' : '#015353')};
  background-color: ${(props) => (props.selected ? '#015353' : '#F2F2F2')};
  font-family: Karla;
  font-size: 20px;
  height: 75px;
  font-weight: bold;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 45px;
  min-width: 154px;
  padding: 0 25px;
`;

export const CheckboxContainer = styled.div`
  margin-left: 20px;
`;

export const ArrowButton = styled.button`
  border: none;
  color: #015353;
  font-weight: bold;
  height: 100%;
  width: 35px;
`;

export const ScrollableContent = styled.div`
  overflow-x: auto;
`;

export const DashboardContainer = styled.div`
  max-width: 1360px;
  min-width: 880px;
  margin: 0 85px;

  @media (max-width: 1050px) {
    margin: 0;
  }
`;
