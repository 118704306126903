import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Helpers/Singleton';
import { IStateStore } from 'Helpers/IStateStore';
import { PeersCallApiDataService } from './Services/PeersCallApiDataService';

export class PeersCallModuleStore extends Singleton implements IStateStore {
  private peersCallApiDataService: PeersCallApiDataService;
  private atomStore = getDefaultStore();
  private loadingAtom;
  private linkAtom;

  constructor() {
    super();

    this.peersCallApiDataService = new PeersCallApiDataService();
    this.loadingAtom = atom(true);
    this.linkAtom = atom(null);
  }

  public Use = () => {
    useAtom(this.loadingAtom);
    useAtom(this.linkAtom);

    return this;
  };

  public get IsLoading(): boolean {
    return this.atomStore.get(this.loadingAtom);
  }

  private set IsLoading(isLoading: boolean) {
    this.atomStore.set(this.loadingAtom, isLoading);
  }

  public get PeersLink() {
    return this.atomStore.get(this.linkAtom);
  }

  public initialize = async ({ userId, ngmId, organizationId }) => {
    if ((!userId && !ngmId) || !organizationId) {
      // if we already have the link, or don't have the required info to make the call, then return
      return;
    }

    this.IsLoading = true;

    const { response } = await this.peersCallApiDataService.startCall({ userId, ngmId, organizationId });

    const peersLink = `https://${process.env.PEERS_DOMAIN}/#/admin/pyx_to_peer_profile?data=${response}`;

    this.atomStore.set(this.linkAtom, peersLink);

    this.IsLoading = false;
  };
}
